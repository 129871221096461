<script>
import appConfig from '@src/app.config'
import PageHeader from "@components/page-header.vue";
import FullSiteFooter from '@components/full-site-footer.vue'
import Layout from '@layouts/main.vue'
import {Carousel3d, Slide} from 'vue-carousel-3d';
import SsrCarousel from 'vue-ssr-carousel'

require("@src/vendor/digitalb2b");

const scrollEvent = () => {
    const clientHeight = document.documentElement.clientHeight;
    const items = document.getElementsByClassName('animate-item');
    const offsetTop = document.documentElement.scrollTop + clientHeight;

    let check = true;
    for (const item of items) {
        if (item.offsetTop < offsetTop) {
            if (!item.classList.contains('animated')) {
                item.classList.add('animated');
            }
        }

        if (!item.classList.contains('animated')) {
            check = false;
        }
    }

    if (check) {
        window.removeEventListener('scroll', scrollEvent);
    }
}

Number.prototype.lead0 = function (n) {
    var nz = "" + this;
    while (nz.length < n) {
        nz = "0" + nz;
    }
    return nz;
};

export default {
    page: {
        meta: [{name: 'description', content: appConfig.description}],
    },
    components: {
        PageHeader,
        FullSiteFooter,
        SsrCarousel,
        Carousel3d,
        Layout,
        Slide
    },
    data() {
        return {
            supportBrowsers: false,
            videoControls: false,
            mobileNavigationBg: false,
            currentYear: new Date().getFullYear(),
            headerLinks: [
                {
                    text: 'Цифровой житель',
                    external: false,
                    href: null,
                    scrollBlock: 'people'
                },
                {
                    text: 'Цифровой туризм',
                    external: false,
                    href: null,
                    scrollBlock: 'tourism'
                },
                {
                    text: 'Посещаемость',
                    external: false,
                    href: null,
                    scrollBlock: 'business'
                },
                {
                    text: 'Геоэффект бизнес',
                    external: false,
                    href: null,
                    scrollBlock: 'geoeffect'
                },
                {
                    text: 'Документация',
                    external: false,
                    href: null,
                    scrollBlock: 'documents'
                },
            ],
        }
    },
    created() {
        const browser = this.getBrowser();
        if ((browser.name === 'Chrome' && browser.version > 98) ||
            (browser.name === 'Opera' && browser.version > 79)) {
            this.supportBrowsers = true
        }

        window.addEventListener("scroll", scrollEvent);
    },
    mounted() {
        const controls = {
            video: document.getElementById("geoeffect-video"),
            play: document.getElementById("geoeffect-playpause"),
            pause: document.getElementById("geoeffect-controls-playpause"),
            buffered: document.getElementById("geoeffect-buffered"),
            progress: document.getElementById("geoeffect-progress"),
            progressCurrent: document.getElementById('geoeffect-progress-current'),
            total: document.getElementById('geoeffect-total'),
            current: document.getElementById('geoeffect-currenttime'),
            hasHours: false,
        };

        const video = document.getElementById("geoeffect-video");

        const formatTime = (time, hours) => this.formatTime(time, hours);
        const totalWidth = controls.total.getClientRects()[0].width;

        video.addEventListener("canplay", () => {
            controls.hasHours = (video.duration / 3600) >= 1.0;
            controls.duration = formatTime(video.duration, controls.hasHours);
            controls.current.innerText = formatTime(0, controls.hasHours);
        });

        video.addEventListener("timeupdate", () => {
            controls.current.innerText = formatTime(video.currentTime, controls.hasHours);
            const progress = Math.floor(video.currentTime) / Math.floor(video.duration);
            controls.progressCurrent.style.width = Math.floor(progress * totalWidth) + "px";
        });

        video.addEventListener('ended', () => {
            this.videoControls = !this.videoControls;

            controls.current.innerText = formatTime(video.currentTime, controls.hasHours);
            const progress = Math.floor(video.currentTime) / Math.floor(video.duration);
            controls.progressCurrent.style.width = Math.floor(progress * totalWidth) + "px";
        })
    },
    methods: {
        formatTime(time, hours) {
            if (hours) {
                const h = Math.floor(time / 3600);
                const time = time - h * 3600;

                const m = Math.floor(time / 60);
                const s = Math.floor(time % 60);

                return h.lead0(2) + ":" + m.lead0(2) + ":" + s.lead0(2);
            } else {
                const m = Math.floor(time / 60);
                const s = Math.floor(time % 60);

                return m.lead0(2) + ":" + s.lead0(2);
            }
        },

        playpauseEvent() {
            const video = document.getElementById("geoeffect-video");

            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }

            this.videoControls = !this.videoControls;
        },
        getBrowser() {
            var ua = navigator.userAgent;
            var tem;
            var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return {
                name: M[0],
                version: M[1]
            };
        },
        scrollToBlock({target}) {
            event.preventDefault();
            if (target.getAttribute('data-block')) {
                const item = document.getElementById(target.getAttribute('data-block'));
                window.scrollTo({
                    top: item.offsetTop - 70,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
        getHost() {
            return window.location.host;
        },
        systemUrl() {
            return process.env.VUE_APP_SYSTEM_URL || 'https://system.geoeffect.ru/';
        }
    }
}
</script>

<template>
    <Layout class="landing-page">
        <PageHeader
            :links="headerLinks"
            :logo-link="false"
            :show-login-button="true"
        />

        <div class="main-screen animate-item">
            <div class="main-screen-content">
                <div class="presentation-bg"></div>
                <div class="text-block-wrap">
                    <div class="text-block">
                        <h1>Геоэффект</h1>
                        <p>Платформа для аналитики населения, турпотока и локаций <br> на основе геотрекинга, мобильного
                            трафика, <br> экосистемы МТС и данных партнёров</p>
                    </div>
                    <div class="text-footer-block">

                        <div class="btn"
                             data-product="GeoEffect"
                             data-vitrine="Геоэффект"
                             data-btn-view="hiden"
                             data-bo-hw-lead-button="true"
                             data-bo-required-inn="true"
                             data-bo-not-required="email"
                             data-bo-subline=""
                             data-btn-text="Попробовать"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="data-presentation animate-item" id="people">
            <div class="data-presentation-content">
                <div class="block-header mb-6">
                    <h2>Качественные, охватные и оперативные данные</h2>
                    <p>Визуализация динамики численности, портрета, плотности и поведенческих характеристик</p>
                </div>
                <div class="block-content">
                    <div class="presentation-items">
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Население</h3>
                                <p>Численность и социально-демографический портрет</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Объекты</h3>
                                <p>Посещаемость и популярность локаций</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Туристы</h3>
                                <p>Портрет, характеристики <br> поездок и их длительность</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Доступность объектов</h3>
                                <p>Пешеходная и транспортная <br> доступность инфраструктуры</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Тепловая карта</h3>
                                <p>Распределение плотности <br> населения или туристов</p>
                            </div>
                        </div>
                    </div>
                    <div class="presentation-slider">
                        <Carousel3d
                            height="400"
                            width="640"
                            :controlsVisible="true"
                            :perspective="0"
                            :space="200"
                            :clickable="true"
                        >
                            <slide :index="0">
                                <div class="presentation-slider-image"
                                     style="background-image: url('/assets/images/slider/tourism.png')"></div>
                            </slide>
                            <slide :index="1">
                                <div class="presentation-slider-image"
                                     style="background-image: url('/assets/images/slider/summary_data.png')"></div>
                            </slide>
                            <slide :index="2">
                                <div class="presentation-slider-image"
                                     style="background-image: url('/assets/images/slider/portrait.png')"></div>
                            </slide>
                            <slide :index="3">
                                <div class="presentation-slider-image"
                                     style="background-image: url('/assets/images/slider/tourist_flow.png')"></div>
                            </slide>
                            <slide :index="4">
                                <div class="presentation-slider-image"
                                     style="background-image: url('/assets/images/slider/tourist_flow_2.png')"></div>
                            </slide>
                        </Carousel3d>
                    </div>
                </div>
            </div>
        </div>

        <div class="investments animate-item">
            <div class="investments-content">
                <div class="block-header mb-6">
                    <h2>Почему вам стоит выбрать именно нас?</h2>
                </div>
                <div class="block-content">
                    <div class="block-item">
                        <div class="block-item-blur" :class="{ 'active': supportBrowsers }"></div>
                        <div class="block-item-content">
                            <h3>Точно</h3>
                            <ul>
                                <li>Высокие точность и охват</li>
                                <li>Всегда актуальные данные</li>
                                <li>Нужна конкретная выборка? Сделаем.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="block-item-blur" :class="{ 'active': supportBrowsers }"></div>
                        <div class="block-item-content">
                            <h3>Быстро</h3>
                            <ul>
                                <li>Лёгкий и оперативный доступ к данным</li>
                                <li>Данные за прошлые периоды</li>
                                <li>Вся территория России*</li>
                            </ul>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="block-item-blur" :class="{ 'active': supportBrowsers }"></div>
                        <div class="block-item-content">
                            <h3>Удобно</h3>
                            <ul>
                                <li>Удобный интерфейс</li>
                                <li>Исследования и мониторинг</li>
                                <li>Дашборды и аналитика</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="block-footer">
                    <p>* - доступ к данным в регионах присутствия МТС</p>
                </div>
                <div class="video-block">
                    <div class="video-block-wrapper">
                        <video
                            @click="playpauseEvent"
                            id="geoeffect-video"
                            poster="/assets/images/landing/transparent.png">
                            <source src="/assets/files/geoeffect.mp4" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'/>
                            <source src="/assets/files/geoeffect.webm" type='video/webm; codecs="vorbis,vp8"'/>
                        </video>

                        <div id="geoeffect-playpause"
                             class="playpause"
                             :class="{ 'hidden': videoControls }"
                             @click="playpauseEvent">
                            <div class="icon icon-play"></div>
                        </div>

                        <div id="geoeffect-controls"
                             class="geoeffect-controls"
                             :class="{ 'hidden': !videoControls }">

                            <div class="geoeffect-controls-wrap">
                                <div id="geoeffect-controls-playpause"
                                     class="controls-playpause"
                                     @click="playpauseEvent">
                                    <div class="icon icon-pause"></div>
                                </div>

                                <div id="geoeffect-progress" class="geoeffect-progress">
                                    <div id="geoeffect-total" class="geoeffect-total">
                                        <div id="geoeffect-buffered" class="geoeffect-buffered">
                                            <div id="geoeffect-progress-current" class="geoeffect-current"></div>
                                        </div>
                                    </div>
                                </div>

                                <div id="geoeffect-time" class="geoeffect-time">
                                    <div id="geoeffect-currenttime" class="geoeffect-currenttime">00:00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="digital animate-item" id="tourism">
            <div class="digital-bg"></div>
            <div class="digital-wrap">
                <div class="block-content">
                    <div class="block-header mb-4 mt-0">
                        <h2>Анализ населения и миграции</h2>
                    </div>
                    <div class="block-item citizen-block-item">
                        <div class="block-item-content">
                            <h3>Цифровой житель</h3>
                            <ul>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Социально-демографический портрет жителей
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Дом, работа и другие типы локаций
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Места концентрации жителей в разное время суток
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Фактические данные и тренды о населении
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="block-header mb-4">
                        <h2>Анализ турпотока и характеристики поездок</h2>
                    </div>
                    <div class="block-item tourist-block-item">
                        <div class="block-item-content">
                            <h3>Цифровой туризм</h3>
                            <ul>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Выявление наиболее популярных локаций
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Анализ турпотока
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Поведенческий портрет туриста
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Повышение уровня сервиса и возвращаемости туристов
                                </li>
                                <li>
                                    <div class="icon icon-hexagon"></div>
                                    Пешеходная и транспортная доступность до туристических объектов
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboards animate-item" id="business">
            <div class="block-wrap">
                <div class="block-header">
                    <h2>Посещаемость мероприятий, объектов и территорий</h2>
                    <p>Анализируйте события и мероприятия, исследуйте посещаемость
                        объектов и территорий, отслеживайте динамику изменений</p>
                </div>
                <div class="block-content">
                    <SsrCarousel
                        class="mobile-version"
                        :show-dots="true"
                    >
                        <div class="slide">
                            <div class="block-item">
                                <div class="block-item-content">
                                    <div class="block-item-wrap">
                                        <h3>Исследуйте <span>объекты</span> и <span>территории</span></h3>
                                        <p>Определите посещаемость объектов или территорий, и получите рейтинг их
                                            популярности
                                            для планирования развития инфраструктуры</p>
                                        <div class="img-block img-area"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="block-item">
                                <div class="block-item-content">
                                    <div class="block-item-wrap">
                                        <h3>Анализируйте ваши <span>события</span></h3>
                                        <p>Оценивайте посещаемость культурно-массовых мероприятий и анализируйте портрет
                                            аудитории</p>
                                        <div class="img-block img-events"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="block-item">
                                <div class="block-item-content">
                                    <div class="block-item-wrap">
                                        <h3>On-line <span>мониторинг</span></h3>
                                        <p>Наблюдайте за количеством посетителей объектов, мероприятий и территорий в
                                            моменте, для принятия организационных решений</p>
                                        <div class="img-block img-monitoring"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SsrCarousel>

                    <div class="desktop-version">
                        <div class="block-item">
                            <div class="block-item-content">
                                <div class="block-item-wrap">
                                    <h3>Исследуйте <span>объекты</span> и <span>территории</span></h3>
                                    <p>Определите посещаемость объектов или территорий, и получите рейтинг их
                                        популярности
                                        для планирования развития инфраструктуры</p>
                                    <div class="img-block img-area"></div>
                                </div>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <div class="block-item-wrap">
                                    <h3>Анализируйте ваши <span>события</span></h3>
                                    <p>Оценивайте посещаемость культурно-массовых мероприятий и анализируйте портрет
                                        аудитории</p>
                                    <div class="img-block img-events"></div>
                                </div>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <div class="block-item-wrap">
                                    <h3>On-line <span>мониторинг</span></h3>
                                    <p>Наблюдайте за количеством посетителей объектов, мероприятий и территорий в
                                        моменте, для принятия организационных решений</p>
                                    <div class="img-block img-monitoring"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="business animate-item" id="geoeffect">
            <div class="business-wrap">
                <div class="block-header mb-6">
                    <h2>Геоэффект бизнес</h2>
                    <p>Решайте задачи и повышайте эффективность вашего бизнеса</p>
                </div>
                <div class="block-content">
                    <div class="business-items">
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Поиск новых точек</h3>
                                <p>Оптимальные локации для открытия бизнеса</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Трафик</h3>
                                <p>Места концентрации целевой аудитории</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Рейтинг локаций</h3>
                                <p>Индекс торговых точек <br> для управления логистикой <br> и товарооборотом</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Анализ</h3>
                                <p>Конкурентный анализ <br> и исследования</p>
                            </div>
                        </div>
                        <div class="block-item">
                            <div class="block-item-content">
                                <h3>Расходы</h3>
                                <p>Объемы трат и средние <br> чеки по категориям трат</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-header block-header-demo d-flex flex-column align-center">
                    <h3 class="text-bold">Оцените возможности Геоэффект бизнес</h3>
                    <p>с помощью демоверсии</p>
                    <a :href="systemUrl() + '/geoeffect_promo'" class="btn btn-blue">Перейти к демо</a>
                </div>

                <div class="business-slider">
                    <Carousel3d
                        height="400"
                        width="640"
                        :controlsVisible="true"
                        :perspective="0"
                        :space="200"
                        :clickable="true"
                    >
                        <slide :index="0">
                            <div class="business-slider-image"
                                 style="background-image: url('/assets/images/slider/geoeffect.png')"></div>
                        </slide>
                        <slide :index="1">
                            <div class="business-slider-image"
                                 style="background-image: url('/assets/images/slider/geoeffect_res.png')"></div>
                        </slide>
                        <slide :index="2">
                            <div class="business-slider-image"
                                 style="background-image: url('/assets/images/slider/geoeffect_dashboard.png')"></div>
                        </slide>
                        <slide :index="3">
                            <div class="business-slider-image"
                                 style="background-image: url('/assets/images/slider/geoeffect_multiply.png')"></div>
                        </slide>
                        <slide :index="4">
                            <div class="business-slider-image"
                                 style="background-image: url('/assets/images/slider/geoeffect_multiply_dashboard.png')"></div>
                        </slide>
                    </Carousel3d>
                </div>
            </div>
        </div>

        <div class="question">
            <div class="block-wrap">
                <div class="block-content">
                    <h3>Остались вопросы?</h3>
                    <div class="block-content-body d-flex justify-content-between">
                        <p>Расскажем, покажем и поможем выбрать наиболее подходящий для вас сервис</p>
                        <div
                            id="digital_highway"
                            class="btn"
                            data-product="GeoEffect"
                            data-vitrine="Геоэффект"
                            data-btn-view="hiden"
                            data-bo-required-inn="true"
                            data-bo-not-required="email"
                            data-bo-subline=""
                            data-btn-text="Напишите нам"></div>
                        <div class="btn">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="documents" class="row">
            <div class="col p-0">
                <div class="full-site-footer">
                    <div class="footer-wrap">
                        <div class="row px-4">
                            <div class="col-12 col-md-6 col-lg-3 mb-3">
                                <p class="mb-4">Инструкция по подключению:</p>
                                <ol class="mt-1">
                                    <li>
                                        <p class="btn-text"
                                           data-product="GeoEffect"
                                           data-vitrine="Геоэффект"
                                           data-btn-view="hiden"
                                           data-bo-hw-lead-button="true"
                                           data-bo-required-inn="true"
                                           data-bo-not-required="email"
                                           data-bo-subline=""
                                           data-btn-text="Оставьте заявку"></p>
                                    </li>
                                    <li>Оформите договор</li>
                                    <li>Получите доступ на платформу</li>
                                </ol>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 mb-3">
                                <p class="mb-4">Ценообразование:</p>
                                <p>Стоимость услуг зависит от параметров анализа и доступа. <br>
                                    <span class="btn-text"
                                          data-product="GeoEffect"
                                          data-vitrine="Геоэффект"
                                          data-btn-view="hiden"
                                          data-bo-hw-lead-button="true"
                                          data-bo-required-inn="true"
                                          data-bo-not-required="email"
                                          data-bo-subline=""
                                          data-btn-text="Напишите нам"></span>, опишите задачу и мы поможем вам выбрать
                                    оптимальное решение</p>
                            </div>
                            <div class="col-12 col-md-12 col-lg-5 mb-3">
                                <p class="mb-4">Документация:</p>
                                <p class="mb-4">Система «Геоэффект» внесена в <a href="https://reestr.digital.gov.ru/reestr/1525692/">Реестр отечественного ПО</a></p>
                                <div class="docs">
                                    <a class="file icon icon-doc"
                                       href="/assets/files/description_of_the_functional_characteristics_of_the_software_21.03.docx"><span>Функциональные характеристики ПО</span></a>
                                    <a class="file icon icon-doc"
                                       href="/assets/files/installation_and_operation_requirements_21.03.docx"><span>Требования по установке и эксплуатации</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 px-4">
                        <div class="col-12">
                            <p>© {{ currentYear }} 18+</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style lang="scss">
@import '@design/components/header.scss';
@import '@design/components/full-site-footer.scss';
@import '@design/landing.scss';
</style>

